import React from 'react'
export function Dev_Cont(){
    return(<div className="modal fade bd-example-modal-xl"  id="divDevContComp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">System Setup</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmDevContComp" name="frmDevContComp">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value="okFirstDevContr" />
                              <input type="hidden" name="curl_type" id="curl_type" value="dev_control_d" />
                              <input type="hidden" name="m_type" id="m_type" value="dev_control_d" />
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              
                              <div className="card-body">
                                  <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">System Type</label>
                                              <div className="input-group input-group-sm">
                                                  <select id="dev_system_type" name="dev_system_type" className="form-control required12">
                                                  <option value="0">-Select-</option>
                                                  <option value="1">-Main System-</option>
                                                  <option value="2">-Other System-</option>
                                                    
                                                    
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                      {/* <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Sys Name*</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="sysname" name="sysname" className="form-control required" placeholder="" />
                                              </div>
                                          </div>
                                      </div> */}
                                      <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Status*</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="dev_network_ip" name="dev_network_ip" autocomplete="off" className="form-control required" placeholder="" />
                                              </div>
                                          </div>
                                      </div>

                                      <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Description *</label>
                                              <div className="input-group input-group-sm">
                                                  <textarea control="form-control" id="dev_description" name="dev_description" rows="5" style={{width:"100%"}}>

                                                  </textarea>
                                              </div>
                                          </div>
                                      </div>
                                      
                                  </div>
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save Settings</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Dev_Cont;