import React from 'react';
const Comp_Edit_Patient=(props)=>{
    //alert(JSON.stringify(props.pEditPatient));
        return(<div className="modal fade bd-example-modal-xl"  id="divWaEditPatient" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl111" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Edit Patient</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            {props.pEditPatient.length>0?
            <form id="frmWaEditPatient" name="frmWaEditPatient">
           
            <input type="hidden" name="res_func" id="res_func" value="okSuccEditPt" />
                            <input type="hidden" name="curl_type" id="curl_type" value="updatePt" />
                            <input type="hidden" name="m_type" id="m_type" value="updatePt" />
                            <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                            <input type="hidden" name="pt_id" id="pt_id" value={btoa(props.pEditPatient[0]["id"])} />
                          
                            
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Date</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                {props.pEditPatient[0]["fmt_reg_date"]}
                                            </div>
                                        </div>
                                  </div>
                                
                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">S.No</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="sno" name="sno" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}

                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">OPD No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="opd_no" name="opd_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}
                                
                                </div>
                                <div class="row">
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3 col-form-label">Patient Name</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            {props.pEditPatient[0]["name"]}
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Age</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                              {props.pEditPatient[0]["age"]}
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Gender</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            {props.pEditPatient[0]["gender"]}
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Payee Of *</label>
                                            <div className="input-group input-group-sm col-ms-9 col-md-9">
                                               {props.pEditPatient[0]["receipt_amount"]}
                                            </div>
                                        </div>
                                  </div>
 
                                  <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Address</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                {props.pEditPatient[0]["address"]}
                                            </div>
                                        </div>
                                  </div>
         
                                </div>  
                         <div className="row">
                          <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Doctor</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            <select id="doctor_id" name="doctor_id" className="form-control required11">
                                                  <option value={"0"}>-Doctor-</option>
                                                  {props.listDoctors.map((item,index)=>{
                                                    return <option selected={props.pEditPatient[0]["doctor_id"]===item["id"]} value={item["id"]}>{item["name"]}</option>
                                                  })}

                                            </select>
                                            </div>
                                        </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Department</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            <select id="section_id" name="section_id" className="form-control required11">
                                                  <option value={"0"}>-Department-</option>
                                                  {props.listDepartments.map((item,index)=>{
                                                    return <option selected={props.pEditPatient[0]["section_id"]===item["id"]} value={item["id"]}>{item["name"]}</option>
                                                  })}

                                            </select>
                                            </div>
                                        </div>
                                  </div>
                         <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Diagnosis</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                <select id="diagnosis_id" name="diagnosis_id" className="form-control required11">
                                                  <option value={"0"}>-Diagnosis-</option>
                                                  {props.listDiagnosis.map((item,index)=>{
                                                    return <option selected={props.pEditPatient[0]["diagnosis_id"]===item["id"]} value={item["id"]}>{item["name"]}</option>
                                                  })}

                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  
                                 
                            </div>       
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" id="btnSave" name="btnSave" class="btn btn-primary _btnSetColor">Save</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
            </form>:''}
          </div>
          </div>
          </div>);
    
}
export default Comp_Edit_Patient;
