import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';

import DashboardNew from './user_pages/dashboard_new';
import Patient_Entry from './user_pages/patient_entry';
import Opd_Reports from './user_pages/opd_reports';
import Ipd_Reports from './user_pages/ipd_reports';
import Department from './user_pages/department';
import Diagnosis from './user_pages/diagnosis';
import Doctors from './user_pages/doctors';







class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
  render(){
   
    return <Router>
      <Route exact path="/" component={Pages} />
     
      <Route exact path="/Dashboard" component={DashboardNew}/>
      <Route exact path="/patient_entry" component={Patient_Entry} />
      <Route exact path="/opd_reports" component={Opd_Reports} />
      <Route exact path="/ipd_reports" component={Ipd_Reports} />
      <Route exact path="/deparment" component={Department} />
      <Route exact path="/diagnosis" component={Diagnosis} />
      <Route exact path="/doctors" component={Doctors} />
    
    </Router>
  }
  
}
export default App;